import React from "react";
import { LayoutBox } from "../../components/atoms/LayoutBox";
import Layout from "../../components/Layout";
import { TextBanner } from "../../components/molecules/TextBanner";
import { Link } from "gatsby";

const PrivacyPolicy = () => {
  return (
    <Layout title="Privacy Policy">
      <TextBanner title="Privacy Policy" />
      <LayoutBox>
        <div className="content">
          <p>
            <strong>Purpose&nbsp;</strong>
          </p>
          <p>
            We take the privacy of your information very seriously and we are
            committed to protecting and respecting your privacy.&nbsp;
          </p>
          <p>
            This privacy notice will inform you as to how we process your
            personal data on our website/app, when you subscribe and register to
            our website/app and use our services. This privacy notice also tells
            you about your privacy rights pursuant to the Data Protection Act
            2018, UK GDPR and EU GDPR (&ldquo;Data Protection
            Laws&rdquo;).&nbsp;
          </p>
          <p>
            <strong>Controller for personal data&nbsp;</strong>
          </p>
          <p>
            A &ldquo;controller&rdquo; is a person or organisation who alone or
            jointly determines the purposes for which and the way any personal
            data is or is likely to be processed. Unless we notify you otherwise
            Neumind is the controller of your personal data for the purpose of
            this website.&nbsp;
          </p>
          <p>
            <strong>Scope&nbsp;</strong>
          </p>
          <p>
            This privacy notice applies to the processing of personal data by us
            in connection with any:
          </p>
          <ul>
            <li>Users of our services via our platform/app.&nbsp;</li>
          </ul>
          <ul>
            <li>
              Suppliers: For the provision of products and services to us by
              suppliers or service providers.
            </li>
            <li>
              Visitors: For the provision of services by us as a visitor on our
              website.&nbsp;
            </li>
          </ul>
          <p style={{ textAlign: "justify" }}>
            <strong>More information for users of our services&nbsp;</strong>
          </p>
          <p style={{ textAlign: "justify" }}>
            Our subscription-based digital platform or app for iOS and Android
            includes
          </p>
          <ul>
            <li>Access to experts and peers through a community forum</li>
            <li>
              Courses for survivors and caregivers aimed to educate and improve
              their ability to self-manage their condition
            </li>
            <li>
              Access to digital &ldquo;Cognitive Tools&rdquo; for survivors and
              caregivers, to help survivors compensate for key cognitive
              deficits
            </li>
            <li>Profile-based recommendations for all of the above</li>
          </ul>
          <p style={{ textAlign: "justify" }}>
            <strong>Types of personal data&nbsp;</strong>
          </p>
          <p>
            Personal data or personal information means any information about an
            individual from which that person can be identified. It does not
            include data where the identity has been removed. This is known as
            anonymised data. Anonymised data falls outside the scope of Data
            Protection Laws.&nbsp;
          </p>
          <p style={{ textAlign: "justify" }}>
            Neumind may collect, use, store and transfer different kinds of
            personal data about you which we have grouped together:&nbsp;
          </p>
          <ul>
            <li>
              <strong>Identity Data</strong> includes first name, last name,
              username or similar identifier and title.&nbsp;
            </li>
            <li>
              <strong>Contact Data</strong> includes billing address, delivery
              address, email address and telephone numbers.
            </li>
            <li>
              <strong>Financial Data</strong> includes bank account and payment
              card details.
            </li>
            <li>
              <strong>Transaction Data</strong> includes details about payments
              to and from you and other details for the provision of services
              you have purchased from us.
            </li>
            <li>
              <strong>Technical Data</strong> includes internet protocol (IP)
              address, your login data, browser type and version, time zone
              setting and location, browser plug-in types and versions,
              operating system and platform and other technology on the devices
              you use to access our website.&nbsp;
            </li>
            <li>
              <strong>Profile Data </strong>includes your username and password,
              searches made by you, your ratings and comments, preferences,
              feedback and survey responses.&nbsp;&nbsp;
            </li>
            <li>
              <strong>Usage Data</strong> includes information about how you use
              our website and services.&nbsp;
            </li>
            <li>
              <strong>Marketing and Communications Data </strong>includes your
              preferences in receiving marketing from us and our third parties
              and your communication preferences.
            </li>
          </ul>
          <p>
            <strong>How we collect your personal data</strong>
          </p>
          <p>
            <span>
              <strong>Directly from you</strong>
            </span>
            <span>
              : You give us your personal data in your direct interactions with
              us. Such personal data includes Identity Data, Contact Data,
              Financial Data, Profile Data, Usage Data, Technical Data,
              Marketing and Communications Data (i) by filling in forms on our
              website (ii) by leaving ratings and comments (iii) by
              corresponding with us by email or post (iv) by calling us over the
              telephone.&nbsp;
            </span>
          </p>
          <p>
            <span>This includes personal data you provide when you:&nbsp;</span>
          </p>
          <ul>
            <li>
              <span>register to become a user of our services.&nbsp;</span>
            </li>
            <li>
              <span>subscribe to our service or publications.&nbsp;</span>
            </li>
            <li>
              <span>request marketing to be sent to you.</span>
            </li>
            <li>
              <span>
                give us feedback or contact us if you have any concerns.&nbsp;
              </span>
            </li>
            <li>
              <span>
                participate in our marketing or other promotional events.
              </span>
            </li>
          </ul>
          <p>
            <span style={{ textDecoration: "underline" }}>
              From use of our website
            </span>
          </p>
          <p>
            Neumind gathers information and statistics collectively about
            visitors to our website. Analysis of this information demonstrates
            the most frequently used sections of the website and assists us in
            continually improving the online service.
            <span>
              You give us your personal data, which includes Profile Data, Usage
              Data, Technical Data and/or Marketing and Communications Data when
              you use our website or which we have agreed with you to use or
              when you review any publications or marketing material we send
              you.
            </span>
            Please see our <Link to="/cookies-notice">cookies notice</Link> for
            more information.
          </p>
          <p>
            <span>
              <strong>Indirectly: Third-party sources:</strong>
            </span>
            <span>
              We receive Identity Data, Contact Data, Financial Data, about you
              from third parties when:
            </span>
          </p>
          <ul>
            <li>
              <span>
                we provide our services or other parties send us your personal
                data to enable the provision of those service.&nbsp;
              </span>
            </li>
            <li>
              <span>
                you provide your personal data to a third party for the purpose
                of sharing it with us.&nbsp;
              </span>
            </li>
          </ul>
          <p>
            <strong>Providing personal data&nbsp;</strong>
          </p>
          <p>
            Where we need to collect personal data by law or under the terms of
            a contract, we have with you and you do not provide that information
            when requested we may not be able to perform the contract we have or
            are trying to enter into with you for example to provide you with
            our services. In this case we may have to cancel our service, but we
            will notify you if this is the case at the time.&nbsp;
          </p>
          <p>
            <strong>How we use your personal data&nbsp;</strong>
          </p>
          <p>
            Neumind will only use your personal data when the law allows us to.
            We will use your personal data in the following circumstances:
          </p>
          <ul>
            <li>
              <span style={{ textDecoration: "underline" }}>
                Performance of a contract:
              </span>{" "}
              Where we need to perform the contract we are about to enter into
              or have entered into with you. For example when you sign up to our
              services and use our services via our app.&nbsp;
            </li>
            <li>
              <span style={{ textDecoration: "underline" }}>
                Legal obligation:
              </span>{" "}
              Where we need to comply with a legal obligation. For example for
              accounting or legal purposes.&nbsp;
            </li>
            <li>
              <span style={{ textDecoration: "underline" }}>
                Legitimate interests:
              </span>{" "}
              Where it is necessary for our legitimate interests or those of a
              third party and your interests and fundamental rights do not
              override those interests.
            </li>
            <li>
              <span style={{ textDecoration: "underline" }}>Consent:</span> We
              may rely on consent as a legal basis for processing your personal
              data. Where we do rely on consent you have the right to withdraw
              consent at any time. Please contact us at{" "}
              <a
                href="mailto:team@neumind.co.uk"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>team@neumind.co.uk</span>
              </a>{" "}
              to withdraw consent. Please also see{" "}
              <span style={{ textDecoration: "underline" }}>
                Marketing communications
              </span>
              .&nbsp;
            </li>
          </ul>
          <p>
            <strong>Purposes for which we will use your personal data</strong>
          </p>
          <p>
            We have set out below in a table format a description of all the
            ways we plan to use your personal data and which of the legal bases
            we rely on to do so. We have also identified what our legitimate
            interests are where appropriate.
          </p>
          <p>
            Note that we may process your personal data for more than one lawful
            ground depending on the specific purpose for which we are using your
            data. Please contact us if you need details about the specific legal
            ground we are relying on to process your personal data where more
            than one ground has been set out in the table below.&nbsp;
          </p>
          <div>
            <table>
              <tbody>
                <tr>
                  <td>
                    <strong>Purpose/Activity</strong>
                  </td>
                  <td>
                    <strong>Type of data</strong>
                  </td>
                  <td>
                    <strong>
                      Lawful basis for processing including basis of legitimate
                      interest
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    Installing our app for the provision of our services to you
                  </td>
                  <td>(a) Identity&nbsp;(b) Contact</td>
                  <td>Consent&nbsp;</td>
                </tr>
                <tr>
                  <td>
                    To register you as a member for the provision of our
                    services
                  </td>
                  <td>(a) Identity&nbsp;(b) Contact</td>
                  <td>Performance of a contract with you</td>
                </tr>
                <tr>
                  <td>To respond to any enquires&nbsp;</td>
                  <td>(a) Identity&nbsp;(b) Contact</td>
                  <td>
                    (a) Performance of a contract with you(b) Necessary for our
                    legitimate interests
                  </td>
                </tr>
                <tr>
                  <td>
                    To process and deliver our services to you including:(a)
                    Manage payments, fees and charges(b) Collect and recover
                    money owed to us
                  </td>
                  <td>
                    (a) Identity&nbsp;(b) Contact&nbsp;(c) Financial&nbsp;(d)
                    Transaction&nbsp;(e) Marketing and Communications
                  </td>
                  <td>
                    (a) Performance of a contract with you&nbsp;(b) Necessary
                    for our legitimate interests i.e., to recover debts due to
                    us
                  </td>
                </tr>
                <tr>
                  <td>
                    To manage our relationship with you which will include:(a)
                    Notifying you about changes to our terms or privacy
                    notice(b) Asking you to leave a review or take a survey
                  </td>
                  <td>
                    (a) Identity&nbsp;(b) Contact&nbsp;(c) Profile&nbsp;(d)
                    Marketing and Communications
                  </td>
                  <td>
                    (a) Performance of a contract with you&nbsp;(b) Necessary to
                    comply with a legal obligation(c) Necessary for our
                    legitimate interests i.e., to keep our records updated and
                    to study how customers use our products/services
                  </td>
                </tr>
                <tr>
                  <td>
                    To administer and protect our business and this website
                    including troubleshooting, data analysis, testing, system
                    maintenance, support, reporting and hosting of data&nbsp;
                  </td>
                  <td>(a) Identity(b) Contact(c) Technical</td>
                  <td>
                    (a) Necessary for our legitimate interests for running our
                    business, provision of administration and IT services,
                    network security, to prevent fraud and in the context of a
                    business reorganisation or group restructuring exercise(b)
                    Necessary to comply with a legal obligation
                  </td>
                </tr>
                <tr>
                  <td>
                    To deliver relevant website content and advertisements to
                    you and measure or understand the effectiveness of the
                    advertising we serve to you
                  </td>
                  <td>
                    (a) Identity&nbsp;(b) Contact&nbsp;(c) Profile&nbsp;(d)
                    Usage&nbsp;(e) Marketing and Communications&nbsp;(f)
                    Technical&nbsp;
                  </td>
                  <td>
                    Necessary for our legitimate interests i.e., to study how
                    customers use our products/services, to develop them, to
                    grow our business and to inform our marketing strategy
                  </td>
                </tr>
                <tr>
                  <td>
                    To use data analytics to improve our website,
                    products/services, marketing, customer relationships and
                    experiences
                  </td>
                  <td>(a) Technical&nbsp;(b) Usage&nbsp;</td>
                  <td>
                    Necessary for our legitimate interests to define types of
                    customers for our products and services, to keep our website
                    updated and relevant, to develop our business and to inform
                    our marketing strategy
                    <span style={{ textDecoration: "underline" }}>
                      Note:
                    </span>{" "}
                    Where applicable consent will be used for data analytics
                    obtained through cookies or similar technologies. See our{" "}
                    <Link to="/cookies-notice">cookies notice</Link>&nbsp;
                  </td>
                </tr>
                <tr>
                  <td>
                    To make suggestions and recommendations to you about our
                    services that may be of interest to you
                  </td>
                  <td>
                    (a) Identity&nbsp;(b) Contact&nbsp;(c) Technical&nbsp;(d)
                    Usage&nbsp;(e) Profile&nbsp;(f) Marketing and Communications
                  </td>
                  <td>
                    Necessary for our legitimate interests to develop our
                    products/services and grow our business
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p>
            <strong>Special categories of data&nbsp;</strong>
          </p>
          <p>
            Due to the nature of our services you may provide special categories
            of personal data to us. Special categories of personal data under
            Data Protection Laws can include health-related information. As a
            user of our services you may provide this information for use of the
            service. Where this is the case, our lawful basis for processing
            special categories of data is with your explicit consent. Examples
            of the type of information that may be uploaded onto our app
            includes but not limited to the following and the level of detail
            will depend on your interaction with the platform/app.&nbsp;
          </p>
          <ul>
            <li>
              Type of injury (using standard high-level medical classifications
              such as traumatic brain injury, stroke, brain tumour etc).
            </li>
            <li>Time since injury or diagnosis.</li>
            <li>Duration of rehabilitation received.</li>
            <li>
              Current focus areas (categorised into common
              cognitive/neuropsychological domain such as memory, fatigue,
              planning, etc).
            </li>
            <li>
              Goals which are user generated and may or may not relate to a
              chosen focus area (e.g. create a morning routine).
            </li>
          </ul>
          <p>
            <strong>Marketing communications&nbsp;</strong>
          </p>
          <p>
            Neumind may send you marketing communication. You have the right to
            object to processing of your personal data for direct marketing
            purposes.&nbsp;You can unsubscribe from receiving marketing
            communications from us by using the unsubscribe methods contained in
            communications we send to you or by contacting us. See{" "}
            <span style={{ textDecoration: "underline" }}>Contact us</span>
            .&nbsp;
          </p>
          <p>
            Where you opt out of receiving marketing communications this will
            not apply to personal data provided to us as a result of registering
            for or using our service, your service experience or other
            interactions with this website.
          </p>
          <p>
            <strong>How we share your personal data</strong>
          </p>
          <p>
            <span>
              We may have to share your personal data with the parties set out
              below:
            </span>
          </p>
          <ul>
            <li>
              <strong>Internally:</strong> Your personal data will be used by
              our employees and contractors who are working on providing your
              services to you on a need-to-know basis.
            </li>
            <li>
              <strong>IT and Communications Suppliers</strong>: This would
              include service providers who support our business including IT
              and communication suppliers and outsourced business support to
              ensure our service runs smoothly.&nbsp;
            </li>
            <li>
              <strong>Payment Service Providers</strong>: This would include
              service providers which assists us in taking payment from you, for
              example when you as a user subscribe to our services.&nbsp;
            </li>
            <li>
              <strong>Professional advisers</strong>: This would include
              lawyers, bankers, auditors and insurers who provide consultancy,
              banking, legal, insurance and accounting services to us.&nbsp;
            </li>
            <li>
              <strong>
                Law enforcement bodies, regulators and other authorities
              </strong>
              : This is to comply with our legal requirements or adhere to good
              practices.
            </li>
            <li>
              <strong>
                Advertising networks and analytics service providers:
              </strong>
              &nbsp;This is to support and display ads on our website, apps and
              other social media tools.
            </li>
            <li>
              <strong>Third parties</strong>: This is in the context of the
              acquisition or transfer of any part of our business or in
              connection with the business reorganisation.
              <span> </span>If a change happens to our business then the new
              owners may use your personal data in the same way as set out in
              this privacy notice.
            </li>
          </ul>
          <p>
            <strong>International transfers</strong>
          </p>
          <p>
            We may transfer and process your personal data outside of the United
            Kingdom to countries where data protection laws are less stringent
            than those in the UK. When we transfer your personal data outside of
            the UK, we only do so to entities that offer our users the same
            level of data protection as that afforded by the UK Data Protection
            Act 2018, UK GDPR and the EU GDPR.&nbsp;
          </p>
          <ol>
            <li>
              We will only transfer your personal information to countries that
              have been deemed to provide an adequate level of protection for
              personal information; or&nbsp;
            </li>
            <li>
              We will use specific contracts approved for use in the UK or EU
              which give personal information the same protection it has in the
              UK/EU. For example, the use of Article 46 UK and EU GDPR safeguard
              mechanisms to transfer personal data endorsed by the UK Government
              or European Commission.&nbsp;
            </li>
          </ol>
          <p>
            To find out more about the transfer mechanism used please contact us
            at{" "}
            <a
              href="mailto:team@neumind.co.uk"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>team@neumind.co.uk</span>
            </a>
            .&nbsp;
          </p>
          <p>
            <strong> </strong>
          </p>
          <p>
            <strong>Data security</strong>
          </p>
          <p>
            We have put in place appropriate security measures to prevent your
            personal data from being accidentally lost, used or accessed in an
            unauthorised way, altered or disclosed. In addition, we limit access
            to your personal data to those employees, agents, contractors and
            other third parties who have a business need to know. They will only
            process your personal data on our instructions and they are subject
            to a duty of confidentiality.&nbsp;
          </p>
          <p>
            The transmission of information via the internet is not completely
            secure. Although we will take reasonable measures to protect your
            personal data, Neumind cannot guarantee the security of your
            information transmitted and any transmission is at your own risk.
          </p>
          <p>
            <strong>Data retention</strong>
          </p>
          <p>
            Neumind will only retain your personal data for as long as
            reasonably necessary to fulfil the purposes we collected it for
            including for the purposes of satisfying any legal, regulatory, tax,
            accounting or reporting requirements. We may retain your personal
            data for a longer period in the event of a complaint or if we
            reasonably believe there is a prospect of litigation in respect to
            our relationship with you.
          </p>
          <p>
            To determine the appropriate retention period for personal
            information we consider the amount, nature and sensitivity of the
            personal information, the potential risk of harm from unauthorised
            use or disclosure of your personal information, the purposes for
            which we process your personal information and whether we can
            achieve those purposes through other means and the applicable legal,
            regulatory, tax, accounting or other requirements.
          </p>
          <p>
            Neumind may anonymise your personal data so that it can no longer be
            associated with you for research or statistical purposes in which
            case we may use this information indefinitely without further notice
            to you.&nbsp;
          </p>
          <p>
            <strong>Children data</strong>
          </p>
          <p>
            This website is not intended for children and we do not knowingly
            collect data relating to children. Pursuant to Data Protection Laws
            this means to individuals under the age of 13 (UK). We do not
            process personal data of children under the age of 16.&nbsp;
          </p>
          <p>
            <strong>Data Subject Rights</strong>
          </p>
          <p>
            Under certain circumstances, you have rights under Data Protection
            Laws. Some of these rights are not absolute. You can:
          </p>
          <p>
            <span style={{ textDecoration: "underline" }}>
              <strong>Request access to your personal data:</strong>
            </span>{" "}
            This is known as a "data subject access request" and enables you to
            receive a copy of the personal data Neumind hold about you.
          </p>
          <p>
            <span style={{ textDecoration: "underline" }}>
              <strong>Request correction of your personal data:</strong>
            </span>{" "}
            This enables you to have any incomplete or inaccurate information we
            hold about you corrected.
          </p>
          <p>
            <span style={{ textDecoration: "underline" }}>
              <strong>Request erasure of your personal data</strong>
            </span>
            : This enables you to ask us to delete or remove personal data where
            there is no good reason for us continuing to process it. Note: We
            may not always be able to comply with your request of erasure for
            specific legal reasons which will be notified to you at the time of
            your request.
          </p>
          <p>
            <span style={{ textDecoration: "underline" }}>
              <strong>Object to processing of your personal data:</strong>
            </span>{" "}
            This is where we are processing your personal data based on a
            legitimate interest or those of a third party and you may challenge
            this.&nbsp; However, we may be entitled to continue processing your
            information based on our legitimate interests or where this is
            relevant to any legal claims.&nbsp; See also{" "}
            <span style={{ textDecoration: "underline" }}>
              Marketing communications.&nbsp;
            </span>
          </p>
          <p>
            <span style={{ textDecoration: "underline" }}>
              <strong>
                Request restriction of processing your personal information
              </strong>
            </span>
            : This enables you to ask us to suspend the processing of your
            personal data in the following scenarios: (a) if you want us to
            establish the information's accuracy (b) where our use of the
            information is unlawful but you do not want us to erase it (c) where
            you need us to hold the information even if we no longer require it
            as you need it to establish, exercise or defend legal claims or (d)
            you have objected to our use of your information but we need to
            verify whether we have overriding legitimate grounds to use it.
          </p>
          <p>
            <span style={{ textDecoration: "underline" }}>
              <strong>
                Request transfer of your personal information (&ldquo;data
                portability&rdquo;)
              </strong>
            </span>
            : This is where in some circumstances we will provide to you or a
            third party you have chosen your personal data in a structured,
            commonly used, machine-readable format.
          </p>
          <p>
            <span style={{ textDecoration: "underline" }}>
              <strong>Right to withdraw consent</strong>
            </span>
            <strong>:</strong> This is where we are relying on consent to
            process your personal data. This will not affect the lawfulness of
            any processing carried out before you withdraw your consent.
            Depending on the processing activity, if you withdraw your consent
            we may not be able to provide certain services to you. We will
            advise you if this is the case at the time you withdraw your
            consent.
          </p>
          <p>
            <span style={{ textDecoration: "underline" }}>
              <strong>Automated decision making: </strong>
            </span>
            This is where decisions are made about you by automated means.
            <strong> </strong>We do not carry out automated decision making
            which results in legal or similar significant effects.&nbsp;
          </p>
          <p style={{ textAlign: "justify" }}>
            <strong>
              Keeping personal information accurate and current&nbsp;
            </strong>
          </p>
          <p>
            It is important that the personal data we hold about you is accurate
            and current. Please keep us informed if your personal data changes
            during your relationship with us. If you wish to update your
            personal data please contact us.&nbsp;
          </p>
          <p>
            <strong>Carrying out your data subject rights&nbsp;</strong>
          </p>
          <p>
            You will not have to pay a fee to access your personal data or to
            exercise any of the other rights. However, we may charge a
            reasonable fee if your request is clearly unfounded, repetitive or
            excessive. We may need to request specific information from you to
            help us confirm your identity and ensure your right to access your
            personal information or to exercise any of your other rights. This
            is a security measure to ensure that personal information is not
            disclosed to any person who has no right to receive it. We may also
            contact you to ask you for further information in relation to your
            request to speed up our response.
          </p>
          <p>
            We try to respond to all legitimate requests within one month.
            Occasionally it may take us longer than a month if your request is
            particularly complex or you have made a number of requests. In this
            case, we will notify you and keep you updated.
          </p>
          <p>
            If you wish to exercise any of the rights set out above, please
            contact us at&nbsp;{" "}
            <a
              href="mailto:team@neumind.co.uk"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>team@neumind.co.uk</span>
            </a>
            .
          </p>
          <p>
            <strong>Concerns and complaints&nbsp;</strong>
          </p>
          <p>
            We would appreciate the chance to deal with your concerns in the
            first instance. Please see{" "}
            <span style={{ textDecoration: "underline" }}>Contact us</span>{" "}
            section. If you have unresolved concerns and you live or work in the
            UK or believe that a personal data breach happened in the UK you
            have the right to complain{" "}
            <span>
              at any time to the Information Commissioner's Office (ICO), the UK
              supervisory authority for data protection issues
              (www.ico.org.uk).&nbsp;
            </span>
          </p>
          <p>
            If you live or work outside the UK or you have a complaint
            concerning our personal data processing activities you may lodge a
            complaint with another supervisory authority. &nbsp;
          </p>
          <p>
            <strong>Changes to our privacy notice&nbsp;</strong>
          </p>
          <p>
            This privacy notice may be changed from time to time in response to
            legal, technical or business developments. We will take appropriate
            measures to inform you when we update our privacy notice. We will
            obtain your consent to any material privacy notice changes if and
            where this is required by applicable Data Protection Laws.
          </p>
          <p>
            <strong>Contact us&nbsp;</strong>
          </p>
          <p>
            If you would like more information about the way we manage personal
            information that we hold about you please contact us at:
          </p>
          <p>
            Email address:&nbsp;
            <a
              href="mailto:team@neumind.co.uk"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>team@neumind.co.uk</span>
            </a>{" "}
            or our <Link to="/contact">contact form</Link>.
          </p>
          <p>This version was last updated on 27 April 2023&nbsp;&nbsp;</p>
        </div>
      </LayoutBox>
    </Layout>
  );
};

export default PrivacyPolicy;
